export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const CommonsAutoFormatTextField = () => import('../../components/Commons/AutoFormatTextField.vue' /* webpackChunkName: "components/commons-auto-format-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonsBankCard = () => import('../../components/Commons/BankCard.vue' /* webpackChunkName: "components/commons-bank-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsChangeLanguageLoading = () => import('../../components/Commons/ChangeLanguageLoading.vue' /* webpackChunkName: "components/commons-change-language-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsChipCurrency = () => import('../../components/Commons/ChipCurrency.vue' /* webpackChunkName: "components/commons-chip-currency" */).then(c => wrapFunctional(c.default || c))
export const CommonsCopyButton = () => import('../../components/Commons/CopyButton.vue' /* webpackChunkName: "components/commons-copy-button" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomQrCode = () => import('../../components/Commons/CustomQrCode.vue' /* webpackChunkName: "components/commons-custom-qr-code" */).then(c => wrapFunctional(c.default || c))
export const CommonsDatePicker = () => import('../../components/Commons/DatePicker.vue' /* webpackChunkName: "components/commons-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadBanner = () => import('../../components/Commons/DownloadBanner.vue' /* webpackChunkName: "components/commons-download-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadByOs = () => import('../../components/Commons/DownloadByOs.vue' /* webpackChunkName: "components/commons-download-by-os" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadPage = () => import('../../components/Commons/DownloadPage.vue' /* webpackChunkName: "components/commons-download-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsFilterForm = () => import('../../components/Commons/FilterForm.vue' /* webpackChunkName: "components/commons-filter-form" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameIframeEmbed = () => import('../../components/Commons/GameIframeEmbed.vue' /* webpackChunkName: "components/commons-game-iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameLoadFailed = () => import('../../components/Commons/GameLoadFailed.vue' /* webpackChunkName: "components/commons-game-load-failed" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBanner = () => import('../../components/Commons/InviteAndEarnBanner.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBannerDesktop = () => import('../../components/Commons/InviteAndEarnBannerDesktop.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const CommonsLanguageSwitcher = () => import('../../components/Commons/LanguageSwitcher.vue' /* webpackChunkName: "components/commons-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const CommonsLoading = () => import('../../components/Commons/Loading.vue' /* webpackChunkName: "components/commons-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsMaintenance = () => import('../../components/Commons/Maintenance.vue' /* webpackChunkName: "components/commons-maintenance" */).then(c => wrapFunctional(c.default || c))
export const CommonsNoSupportPage = () => import('../../components/Commons/NoSupportPage.vue' /* webpackChunkName: "components/commons-no-support-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNotFoundPage = () => import('../../components/Commons/NotFoundPage.vue' /* webpackChunkName: "components/commons-not-found-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNuxtImage = () => import('../../components/Commons/NuxtImage.vue' /* webpackChunkName: "components/commons-nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const CommonsPhoneInput = () => import('../../components/Commons/PhoneInput.vue' /* webpackChunkName: "components/commons-phone-input" */).then(c => wrapFunctional(c.default || c))
export const CommonsPopup = () => import('../../components/Commons/Popup.vue' /* webpackChunkName: "components/commons-popup" */).then(c => wrapFunctional(c.default || c))
export const CommonsSkeletonLoader = () => import('../../components/Commons/SkeletonLoader.vue' /* webpackChunkName: "components/commons-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const CommonsThemeMode = () => import('../../components/Commons/ThemeMode.vue' /* webpackChunkName: "components/commons-theme-mode" */).then(c => wrapFunctional(c.default || c))
export const CommonsVerticalFloatingIcons = () => import('../../components/Commons/VerticalFloatingIcons.vue' /* webpackChunkName: "components/commons-vertical-floating-icons" */).then(c => wrapFunctional(c.default || c))
export const Theme3AgentFloatingIcon = () => import('../../components/Theme3/AgentFloatingIcon.vue' /* webpackChunkName: "components/theme3-agent-floating-icon" */).then(c => wrapFunctional(c.default || c))
export const Theme3Announcement = () => import('../../components/Theme3/Announcement.vue' /* webpackChunkName: "components/theme3-announcement" */).then(c => wrapFunctional(c.default || c))
export const Theme3BetStatus = () => import('../../components/Theme3/BetStatus.vue' /* webpackChunkName: "components/theme3-bet-status" */).then(c => wrapFunctional(c.default || c))
export const Theme3ClaimStatus = () => import('../../components/Theme3/ClaimStatus.vue' /* webpackChunkName: "components/theme3-claim-status" */).then(c => wrapFunctional(c.default || c))
export const Theme3ConfirmDepositDialog = () => import('../../components/Theme3/ConfirmDepositDialog.vue' /* webpackChunkName: "components/theme3-confirm-deposit-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme3DepositBanner = () => import('../../components/Theme3/DepositBanner.vue' /* webpackChunkName: "components/theme3-deposit-banner" */).then(c => wrapFunctional(c.default || c))
export const Theme3FloatingIcon1 = () => import('../../components/Theme3/FloatingIcon1.vue' /* webpackChunkName: "components/theme3-floating-icon1" */).then(c => wrapFunctional(c.default || c))
export const Theme3FloatingIcon2 = () => import('../../components/Theme3/FloatingIcon2.vue' /* webpackChunkName: "components/theme3-floating-icon2" */).then(c => wrapFunctional(c.default || c))
export const Theme3ForceToAddBankAndWallet = () => import('../../components/Theme3/ForceToAddBankAndWallet.vue' /* webpackChunkName: "components/theme3-force-to-add-bank-and-wallet" */).then(c => wrapFunctional(c.default || c))
export const Theme3HoldBalanceDialog = () => import('../../components/Theme3/HoldBalanceDialog.vue' /* webpackChunkName: "components/theme3-hold-balance-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme3ImageBackground = () => import('../../components/Theme3/ImageBackground.vue' /* webpackChunkName: "components/theme3-image-background" */).then(c => wrapFunctional(c.default || c))
export const Theme3MenuBar = () => import('../../components/Theme3/MenuBar.vue' /* webpackChunkName: "components/theme3-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme3Popup = () => import('../../components/Theme3/Popup.vue' /* webpackChunkName: "components/theme3-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme3ProfileLayout = () => import('../../components/Theme3/ProfileLayout.vue' /* webpackChunkName: "components/theme3-profile-layout" */).then(c => wrapFunctional(c.default || c))
export const Theme3TransactionStatus = () => import('../../components/Theme3/TransactionStatus.vue' /* webpackChunkName: "components/theme3-transaction-status" */).then(c => wrapFunctional(c.default || c))
export const Theme3WithdrawalConfirmDeclineDialog = () => import('../../components/Theme3/WithdrawalConfirmDeclineDialog.vue' /* webpackChunkName: "components/theme3-withdrawal-confirm-decline-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesCircle = () => import('../../components/Commons/Pices/Circle.vue' /* webpackChunkName: "components/commons-pices-circle" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesStraight = () => import('../../components/Commons/Pices/Straight.vue' /* webpackChunkName: "components/commons-pices-straight" */).then(c => wrapFunctional(c.default || c))
export const Theme3ArticleContentComponent = () => import('../../components/Theme3/Article/ContentComponent.vue' /* webpackChunkName: "components/theme3-article-content-component" */).then(c => wrapFunctional(c.default || c))
export const Theme3ArticleNoContentAvailable = () => import('../../components/Theme3/Article/NoContentAvailable.vue' /* webpackChunkName: "components/theme3-article-no-content-available" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsActiveBonusLoading = () => import('../../components/Theme3/Components/ActiveBonusLoading.vue' /* webpackChunkName: "components/theme3-components-active-bonus-loading" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimBonusButton = () => import('../../components/Theme3/Components/ClaimBonusButton.vue' /* webpackChunkName: "components/theme3-components-claim-bonus-button" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimHistory = () => import('../../components/Theme3/Components/ClaimHistory.vue' /* webpackChunkName: "components/theme3-components-claim-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimPromoCode = () => import('../../components/Theme3/Components/ClaimPromoCode.vue' /* webpackChunkName: "components/theme3-components-claim-promo-code" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimPromoCodeDialog = () => import('../../components/Theme3/Components/ClaimPromoCodeDialog.vue' /* webpackChunkName: "components/theme3-components-claim-promo-code-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimableSwiperLoading = () => import('../../components/Theme3/Components/ClaimableSwiperLoading.vue' /* webpackChunkName: "components/theme3-components-claimable-swiper-loading" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsDepositIsInProgress = () => import('../../components/Theme3/Components/DepositIsInProgress.vue' /* webpackChunkName: "components/theme3-components-deposit-is-in-progress" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsEndBonusLoading = () => import('../../components/Theme3/Components/EndBonusLoading.vue' /* webpackChunkName: "components/theme3-components-end-bonus-loading" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsExchangeRate = () => import('../../components/Theme3/Components/ExchangeRate.vue' /* webpackChunkName: "components/theme3-components-exchange-rate" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsFriendList = () => import('../../components/Theme3/Components/FriendList.vue' /* webpackChunkName: "components/theme3-components-friend-list" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsNoActiveBonus = () => import('../../components/Theme3/Components/NoActiveBonus.vue' /* webpackChunkName: "components/theme3-components-no-active-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsSideMenu = () => import('../../components/Theme3/Components/SideMenu.vue' /* webpackChunkName: "components/theme3-components-side-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsWithdrawalErrorPopup = () => import('../../components/Theme3/Components/WithdrawalErrorPopup.vue' /* webpackChunkName: "components/theme3-components-withdrawal-error-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopBigPromotionCard = () => import('../../components/Theme3/Desktop/BigPromotionCard.vue' /* webpackChunkName: "components/theme3-desktop-big-promotion-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopCashier = () => import('../../components/Theme3/Desktop/Cashier.vue' /* webpackChunkName: "components/theme3-desktop-cashier" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopCustomFooter = () => import('../../components/Theme3/Desktop/CustomFooter.vue' /* webpackChunkName: "components/theme3-desktop-custom-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopFeaturedContent1 = () => import('../../components/Theme3/Desktop/FeaturedContent1.vue' /* webpackChunkName: "components/theme3-desktop-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopFeaturedContent2 = () => import('../../components/Theme3/Desktop/FeaturedContent2.vue' /* webpackChunkName: "components/theme3-desktop-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopHomeSlider = () => import('../../components/Theme3/Desktop/HomeSlider.vue' /* webpackChunkName: "components/theme3-desktop-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopInterRegisterModal = () => import('../../components/Theme3/Desktop/InterRegisterModal.vue' /* webpackChunkName: "components/theme3-desktop-inter-register-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopIsLoginAndRegistered = () => import('../../components/Theme3/Desktop/IsLoginAndRegistered.vue' /* webpackChunkName: "components/theme3-desktop-is-login-and-registered" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopIsNotLoginAndRegistered = () => import('../../components/Theme3/Desktop/IsNotLoginAndRegistered.vue' /* webpackChunkName: "components/theme3-desktop-is-not-login-and-registered" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopLanguageSwitcher = () => import('../../components/Theme3/Desktop/LanguageSwitcher.vue' /* webpackChunkName: "components/theme3-desktop-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopLoginModal = () => import('../../components/Theme3/Desktop/LoginModal.vue' /* webpackChunkName: "components/theme3-desktop-login-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopNavBar = () => import('../../components/Theme3/Desktop/NavBar.vue' /* webpackChunkName: "components/theme3-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPromotionCard = () => import('../../components/Theme3/Desktop/PromotionCard.vue' /* webpackChunkName: "components/theme3-desktop-promotion-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPromotionContent = () => import('../../components/Theme3/Desktop/PromotionContent.vue' /* webpackChunkName: "components/theme3-desktop-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopRegisterModal = () => import('../../components/Theme3/Desktop/RegisterModal.vue' /* webpackChunkName: "components/theme3-desktop-register-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopSubMenu = () => import('../../components/Theme3/Desktop/SubMenu.vue' /* webpackChunkName: "components/theme3-desktop-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileCustomFooter = () => import('../../components/Theme3/Mobile/CustomFooter.vue' /* webpackChunkName: "components/theme3-mobile-custom-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileCustomFooterTest = () => import('../../components/Theme3/Mobile/CustomFooterTest.vue' /* webpackChunkName: "components/theme3-mobile-custom-footer-test" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileExchangeRateModal = () => import('../../components/Theme3/Mobile/ExchangeRateModal.vue' /* webpackChunkName: "components/theme3-mobile-exchange-rate-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileGameHistory = () => import('../../components/Theme3/Mobile/GameHistory.vue' /* webpackChunkName: "components/theme3-mobile-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileGameMenu = () => import('../../components/Theme3/Mobile/GameMenu.vue' /* webpackChunkName: "components/theme3-mobile-game-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileHomeSlider = () => import('../../components/Theme3/Mobile/HomeSlider.vue' /* webpackChunkName: "components/theme3-mobile-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileMenuDrawer = () => import('../../components/Theme3/Mobile/MenuDrawer.vue' /* webpackChunkName: "components/theme3-mobile-menu-drawer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileMyHistory = () => import('../../components/Theme3/Mobile/MyHistory.vue' /* webpackChunkName: "components/theme3-mobile-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileNavBar = () => import('../../components/Theme3/Mobile/NavBar.vue' /* webpackChunkName: "components/theme3-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobilePlayerBalance = () => import('../../components/Theme3/Mobile/PlayerBalance.vue' /* webpackChunkName: "components/theme3-mobile-player-balance" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileReferFriend = () => import('../../components/Theme3/Mobile/ReferFriend.vue' /* webpackChunkName: "components/theme3-mobile-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileTitleAlert = () => import('../../components/Theme3/Mobile/TitleAlert.vue' /* webpackChunkName: "components/theme3-mobile-title-alert" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopNavProfileDropdown = () => import('../../components/Theme3/Desktop/Nav/ProfileDropdown.vue' /* webpackChunkName: "components/theme3-desktop-nav-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPagesInterRegisterPage = () => import('../../components/Theme3/Desktop/Pages/InterRegisterPage.vue' /* webpackChunkName: "components/theme3-desktop-pages-inter-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPagesRegisterPage = () => import('../../components/Theme3/Desktop/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme3-desktop-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPaymentMethodBankTransfer = () => import('../../components/Theme3/Desktop/PaymentMethod/BankTransfer.vue' /* webpackChunkName: "components/theme3-desktop-payment-method-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPaymentMethodCryptoCurrency = () => import('../../components/Theme3/Desktop/PaymentMethod/CryptoCurrency.vue' /* webpackChunkName: "components/theme3-desktop-payment-method-crypto-currency" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPaymentMethodThirdPartyPayment = () => import('../../components/Theme3/Desktop/PaymentMethod/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme3-desktop-payment-method-third-party-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesBankAccountCard = () => import('../../components/Theme3/Desktop/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme3-desktop-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesCryptoWalletCard = () => import('../../components/Theme3/Desktop/Profiles/CryptoWalletCard.vue' /* webpackChunkName: "components/theme3-desktop-profiles-crypto-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesGameHistory = () => import('../../components/Theme3/Desktop/Profiles/GameHistory.vue' /* webpackChunkName: "components/theme3-desktop-profiles-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyBonus = () => import('../../components/Theme3/Desktop/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyDeposit = () => import('../../components/Theme3/Desktop/Profiles/MyDeposit.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyHistory = () => import('../../components/Theme3/Desktop/Profiles/MyHistory.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyMessage = () => import('../../components/Theme3/Desktop/Profiles/MyMessage.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-message" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyProfile = () => import('../../components/Theme3/Desktop/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesReferFriend = () => import('../../components/Theme3/Desktop/Profiles/ReferFriend.vue' /* webpackChunkName: "components/theme3-desktop-profiles-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesResetPassword = () => import('../../components/Theme3/Desktop/Profiles/ResetPassword.vue' /* webpackChunkName: "components/theme3-desktop-profiles-reset-password" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesWithdrawalPage = () => import('../../components/Theme3/Desktop/Profiles/WithdrawalPage.vue' /* webpackChunkName: "components/theme3-desktop-profiles-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileDepositsBankTransfer = () => import('../../components/Theme3/Mobile/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme3-mobile-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileDepositsCryptoPayment = () => import('../../components/Theme3/Mobile/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme3-mobile-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileDepositsThirdPartyPayment = () => import('../../components/Theme3/Mobile/Deposits/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme3-mobile-deposits-third-party-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobilePagesInterRegisterPage = () => import('../../components/Theme3/Mobile/Pages/InterRegisterPage.vue' /* webpackChunkName: "components/theme3-mobile-pages-inter-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobilePagesRegisterPage = () => import('../../components/Theme3/Mobile/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme3-mobile-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobilePagesWithdrawalPage = () => import('../../components/Theme3/Mobile/Pages/WithdrawalPage.vue' /* webpackChunkName: "components/theme3-mobile-pages-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesBankAccountCard = () => import('../../components/Theme3/Mobile/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme3-mobile-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesMyBonus = () => import('../../components/Theme3/Mobile/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme3-mobile-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesMyDeposit = () => import('../../components/Theme3/Mobile/Profiles/MyDeposit.vue' /* webpackChunkName: "components/theme3-mobile-profiles-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesMyProfile = () => import('../../components/Theme3/Mobile/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme3-mobile-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileWithdrawalsBankTransfer = () => import('../../components/Theme3/Mobile/Withdrawals/BankTransfer.vue' /* webpackChunkName: "components/theme3-mobile-withdrawals-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileWithdrawalsCryptoTransfer = () => import('../../components/Theme3/Mobile/Withdrawals/CryptoTransfer.vue' /* webpackChunkName: "components/theme3-mobile-withdrawals-crypto-transfer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
